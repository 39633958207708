import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import H2 from 'components/typography/h2'
import Promos from 'components/promos'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'
import Section from 'components/section'
import { graphql } from 'gatsby'
import Floater from 'components/floating-grid'
import FeaturesGrid from 'components/features-grid'

const floaterItems = [
  {
    link: '/stihl/chain-saws/',
    imageId: 'chainsawsFloaterImage',
    name: 'Chainsaws',
    alt: 'STIHL Chainsaws',
  },
  {
    link: '/stihl/trimmers-and-brushcutters/',
    imageId: 'trimmersFloaterImage',
    name: 'Trimmers & Brushcutters',
    alt: 'STIHL Trimmers and Brushcutters',
  },
  {
    link: '/stihl/hedge-trimmers/',
    imageId: 'hedgeTrimmersFloaterImage',
    name: 'Hedge Trimmers',
    alt: 'STIHL Hedge Trimmers',
  },
  {
    link: '/stihl/pressure-washers/',
    imageId: 'pressureWashersFloaterImage',
    name: 'Pressure Washers',
    alt: 'STIHL Pressure Washers',
  },
  {
    link: '/stihl/pole-pruners/',
    imageId: 'polePrunersFloaterImage',
    name: 'Pole Pruners',
    alt: 'STIHL Pole Pruners',
  },
  {
    link: '/stihl/categories/',
    imageId: 'allFloaterImage',
    name: 'View All Categories',
    alt: 'View all STIHL categories',
  },
]

const gridItems = [
  {
    title: 'Trimmers',
    button: {
      link: '/stihl/trimmers-and-brushcutters/',
      text: 'View STIHL Trimmers',
    },
    copy: 'STIHL trimmers and brushcutters are made for those who truly appreciate a well-groomed landscape. If you’re like us, there’s nothing more satisfying than the sound of a whirring trimmer line and the smell of freshly cut grass. There’s also nothing more frustrating than unreliable equipment preventing you from taming the weeds that creep onto your picturesque patch of land. When such adversity occurs, glove up and reach for a STIHL trimmer or brushcutter.',
    imageId: 'trimmersImage',
    imageAlt: 'Man using a STIHL trimmer to cut grass around a fence',
  },
  {
    title: 'Chainsaws',
    button: {
      link: '/stihl/chain-saws/',
      text: 'View STIHL Chainsaws',
    },
    copy: 'Whether you’re a homeowner looking to cut branches on your property or a professional looking to fell the toughest trees, STIHL is the name trusted for generations. STIHL chainsaws offer great cutting power, advanced cutting technology and amazing dependability. When it comes to chainsaws, there is STIHL and then there is everyone else.',
    imageId: 'chainsawsImage',
    imageAlt: 'Man using a STIHL MS 250 chainsaw to cut a log',
  },
  {
    title: 'Blowers',
    button: {
      link: '/stihl/blowers-and-shredder-vacs/',
      text: 'View STIHL Blowers',
    },
    copy: 'Your landscape, your rules. Keep leaves, clippings and other yard debris under control with STIHL handheld blowers, backpack blowers, or shredder vacs. From homeowners to professional landscapers, STIHL is the name to trust for hardworking equipment that lasts, season after season. Each model is designed for efficiency, ease of use and exceptional power. Whether you maintain a small urban lot or acres of land, there’s a STIHL handheld or backpack blower ready to exceed your expectations.',
    imageId: 'blowersImage',
    imageAlt: 'Person using a STIHL BG 56 blower to clear leaves from a walkway',
  },
  {
    title: 'Hedge Trimmers',
    button: {
      link: '/stihl/hedge-trimmers/',
      text: 'View STIHL Hedge Trimmers',
    },
    copy: 'Whether you’re a homeowner looking to cut branches on your property or a professional looking to fell the toughest trees, STIHL is the name trusted for generations. STIHL chainsaws offer great cutting power, advanced cutting technology and amazing dependability. When it comes to chainsaws, there is STIHL and then there is everyone else.',
    imageId: 'hedgeTrimmersImage',
    imageAlt: 'Man using STIHL hedge trimmers to trim a large hedge',
  },
  {
    title: 'Battery',
    button: {
      link: '/stihl/battery/',
      text: 'View STIHL Battery Products',
    },
    copy: 'The STIHL Lightning Battery System® is available in three tiers of performance, including occasional use, frequent and extensive. Whether you’re a landscaping professional or weekend warrior, STIHL Lightning strikes the perfect balance of performance, features and value, giving you the power to finish the job on a single charge.',
    imageId: 'batteryProductsImage',
    imageAlt: 'Woman using a STIHL FSA 56 battery trimmer to cut grass around a fence',
  },
]

const StihlPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => {
      promos.push(node)
    })
  }
  return (
    <Layout>
      <Helmet>
        <title>STIHL | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson offers a full line of high-quality STIHL outdoor power tools, including: chainsaws, trimmers, pressure washers, blowers, edgers, and so much more.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient heading='STIHL' image={heroImage} />

      <Content>
        <Floater
          items={floaterItems.map(item => {
            item.image = data[item.imageId]
            return item
          })}
          header='STIHL Equipment'
        />

        <Section>
          <H2>STIHL products at Hutson Inc</H2>
          <p>
            Hutson Inc is one of the largest providers of STIHL Equipment in the region. STIHL is
            known for being the number one brand of gasoline-powered handheld outdoor power
            equipment in America. Being such a legendary brand, we knew that STIHL was the perfect
            product to put on our showroom floor.
          </p>
          <p>
            We carry a full line of equipment and support them with genuine parts and quality
            service that Hutson is known for. Not sure what product is the perfect fit for you? Our
            parts department have been trained and certified by STIHL. They have the knowledge to
            help you decide which machine is the perfect fit for you.
          </p>
        </Section>

        <Promos data={promos} type='STIHL' />
      </Content>

      <FeaturesGrid
        features={gridItems.map(obj => {
          const image = data[obj.imageId].childImageSharp.gatsbyImageData
          return {
            ...obj,
            image,
          }
        })}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allHutsonPromotion(
      filter: { tags: { in: ["stihl"] } }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/stihl-bg.jpg" }) {
      ...FullWidthImage
    }
    batteryProductsImage: file(relativePath: { eq: "stihl/stihl-battery-products.jpg" }) {
      ...SharpImage900
    }
    blowersImage: file(relativePath: { eq: "stihl/stihl-blowers.jpg" }) {
      ...SharpImage900
    }
    chainsawsImage: file(relativePath: { eq: "stihl/stihl-chainsaws.jpg" }) {
      ...SharpImage900
    }
    hedgeTrimmersImage: file(relativePath: { eq: "stihl/stihl-hedge-trimmers.jpg" }) {
      ...SharpImage900
    }
    trimmersImage: file(relativePath: { eq: "stihl/stihl-trimmers.jpg" }) {
      ...SharpImage900
    }
    chainsawsFloaterImage: file(relativePath: { eq: "stihl/chain-saws.jpg" }) {
      ...FloatingGridImage
    }
    trimmersFloaterImage: file(relativePath: { eq: "stihl/trimmers-and-brushcutters.jpg" }) {
      ...FloatingGridImage
    }
    hedgeTrimmersFloaterImage: file(relativePath: { eq: "stihl/hedge-trimmers.jpg" }) {
      ...FloatingGridImage
    }
    pressureWashersFloaterImage: file(relativePath: { eq: "stihl/pressure-washers.jpg" }) {
      ...FloatingGridImage
    }
    polePrunersFloaterImage: file(relativePath: { eq: "stihl/pole-pruners.jpg" }) {
      ...FloatingGridImage
    }
    allFloaterImage: file(relativePath: { eq: "stihl/all-categories.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default StihlPage
